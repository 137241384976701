/* TODO: Should orgainze these styles in some fashion and import them probably */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DM Sans';
    src: url('../public/fonts/DMSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url('../public/fonts/DMSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
  }

  a {
    @apply text-blue-400;
  }
}
